// import Detect from './Detect'
import Stage from './Stage'
import Emitter from './Emitter'
// import Keyboard from './Keyboard'
import Loader from './Loader'
// import Mouse from './Mouse'
// import Tests from './Tests'
// import GUI from './GUI'
// import Performance from './Performance'
import Router from './Router'

// Re export every helper singleton
export { default as Emitter } from './Emitter'
// export { default as Detect } from './Detect'
export { default as Stage } from './Stage'
// export { default as Keyboard } from './Keyboard'
export { default as Loader } from './Loader'
// export { default as Mouse } from './Mouse'
// export { default as Tests } from './Tests'
// export { default as GUI } from './GUI'
export { default as Router } from './Router'
// export { default as Performance } from './Performance'
