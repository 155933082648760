import Component from '~/components/component';
// import Swiper JS
// import Swiper from 'swiper';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

export default class HomepageProjects extends Component {
	constructor(...args) {
		super(...args);
		this._current = 0;
		this._canClick = true;
		this._firstClick = true;
		this._delay = 5000;
		this._isAutoplay = true;
		
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.slider = this.dom.component.querySelector('.js-slider');
		this.dom.image = this.dom.component.querySelectorAll('.js-image');
		this.dom.timer = this.dom.component.querySelectorAll('.js-timer');
		this.dom.gallery = this.dom.component.querySelectorAll('.js-gallery');
		this.dom.content = this.dom.component.querySelectorAll('.js-content');
		this.dom.count = this.dom.component.querySelectorAll('.js-count');
		this.dom.category = this.dom.component.querySelector('.js-category');
		this.dom.line = this.dom.component.querySelectorAll('.js-line');
	}
	
	init() {
		super.init();

		this._initSlider();

		this._int = setInterval(() => {
			this._autoSwitchTo();
		}, this._delay);
		gsap.fromTo(this.dom.timer[0], {scaleX: 0}, { scaleX: 1, delay: 1, ease: "none", duration: (this._isAutoplay) ? 3.5 : 0.5 })
	}

	bind() {
		super.bind();

		for (let i = 0; i < this.dom.gallery.length; i++) {
			const gallery = this.dom.gallery[i];
			gallery.addEventListener('click', () => {
				this._clickTo(i)
			});
		}
	}

	_initSlider() {
		for (let i = 0; i < this.dom.line.length; i++) {
			const line = this.dom.line[i];
			new SplitText(line, { type: "lines", linesClass: "lineChild" });
			new SplitText(line, { type: "lines", linesClass: "lineParent" });
		}


		gsap.set(this.dom.count[this._current], { autoAlpha: 1 });
		gsap.set([this.dom.content[this._current], this.dom.content[this._current].querySelectorAll('.js-line')], { autoAlpha: 1 });
		gsap.set(this.dom.image[this._current], { zIndex: 3 });
		gsap.set(this.dom.content[this._current].querySelectorAll('.lineChild'), { y: '0%' });
	}

	_autoSwitchTo() {
		if (!this._canClick)
			return;
		
		this._switchTo(loopIndex(this._current + 1, this.dom.gallery.length));
	}

	_clickTo(index) {
		if (!this._canClick)
			return;
		
		clearInterval(this._int);
		this._isAutoplay = false;

		if (this._firstClick) {
			gsap.to(this.dom.timer[this._current], { autoAlpha: 0, duration: 0.1})
			this._firstClick = false;
		} else {
			gsap.set(this.dom.timer, { autoAlpha: 1})
		}

		this._switchTo(index);
	}

	_switchTo(next) {
		if (!this._canClick)
			return;
		
		this._canClick = false;
		this._clearGallery();

		this.dom.gallery[next].classList.add('active');
		this.dom.count.innerHTML = (next + 1);
		this.dom.category.innerHTML = this.dom.content[next].getAttribute('data-category');

		gsap.set(this.dom.image[next], { zIndex: 2 });
		
		gsap.set(this.dom.timer, { scaleX: 0, duration: 0 });
		gsap.to(this.dom.timer[next], { scaleX: 1, delay: 1, ease: "none", duration: (this._isAutoplay) ? 3.5 : 0.5 })
	
	
		let tl = gsap.timeline({
			onComplete: () => {
				gsap.set(this.dom.image[this._current], { zIndex: 1, height: '100%' });
				gsap.set(this.dom.image[next], { zIndex: 3, height: '100%' });
				
				this._current = next;
				this._canClick = true;
			}
		});
		tl.fromTo(this.dom.count[this._current], {
			y: '0%',
		}, {
			duration: 0.5,
			y: '-100%',
			ease: "power2.in",
			stagger: 0.08
		}, 0)
		.to(this.dom.count[next], { autoAlpha: 1, duration: 0 })
		.fromTo(this.dom.count[next], {
			y: '100%',
		}, {
			duration: 0.5,
			y: '0%',
			ease: "power2.out",
			stagger: 0.08
		})
		tl.fromTo(this.dom.content[this._current].querySelectorAll('.lineChild'), {
				y: '0%',
			}, {
				duration: 0.5,
				y: '-100%',
				ease: "power2.in",
				stagger: 0.08
			}, 0)
			.to(this.dom.content[next], { autoAlpha: 1, duration: 0 })
			.fromTo(this.dom.content[next].querySelectorAll('.lineChild'), {
				y: '100%',
			}, {
				duration: 0.5,
				y: '0%',
				ease: "power2.out",
				stagger: 0.08
			}).fromTo(this.dom.image[this._current], {
				height: '100%'
			}, {
				duration: 0.7,
				height: '0%',
				ease: "power2.out"
			}, 0.3).fromTo(this.dom.image[this._current].querySelector('.js-bg'), {
				y: 0
			}, {
				duration: 2,
				y: -100,
				ease: "power2.out"
			}, 0.3).fromTo(this.dom.image[next].querySelector('.js-bg'), {
				y: 100
			}, {
				duration: 2,
				y: 0,
				ease: "power2.out"
			}, 0.3);
	}

	_clearGallery() {
		this.dom.gallery.forEach(gallery => {
			gallery.classList.remove('active');
		});
	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
