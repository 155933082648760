import Component from '~/components/component';
import { gsap } from 'gsap';

export default class Hero extends Component {
	constructor(...args) {
		super(...args);
		this._current = 0;
		this._delay = 3000;
		
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.clock = this.dom.component.querySelector('.js-clock');
		this.dom.wordParent = this.dom.component.querySelectorAll('.js-word-parent');
		this.dom.little = this.dom.component.querySelector('.js-ref-little');
		this.dom.title = this.dom.component.querySelector('.js-title');
		this.dom.big = this.dom.component.querySelector('.js-ref-height');
		this.dom.translate = this.dom.component.querySelector('.js-menu-translate');
	}

	init() {
		super.init();

		this._int = setInterval(() => {
			this.currentTime();
		}, 1000);


		for (let i = 0; i < this.dom.wordParent.length; i++) {
			const parent = this.dom.wordParent[i];
			this.setWordAnimation(parent, i);
		}
	}

	bind() {
		super.bind();

		gsap.set(this.dom.translate, {
			y: this.dom.big.clientHeight
		})

		if (window.innerWidth > 835) {
			window.addEventListener('load', () => {
				gsap.to(this.dom.big, {
					duration: 1.2,
					delay: 1,
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
					ease: "power4.inOut",
					onComplete: () => {
						gsap.to(this.dom.big, {autoAlpha: 0, height: 0, duration: 0});
					}
				})
				gsap.to(this.dom.translate, {
					duration: 1.2,
					delay: 1,
					y: 0,
					ease: "power4.inOut"
				})
				gsap.to(this.dom.little, {
					duration: 1.2,
					delay: 1.3,
					clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
					ease: "power4.out"
				})
				gsap.to(this.dom.title, {
					duration: 1.2,
					delay: 1.3,
					clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
					ease: "power4.out"
				})
			})
		}else{
			gsap.to(this.dom.big, {autoAlpha: 0, height: 0, duration: 0})
			gsap.to(this.dom.little, {
				duration: 1.2,
				delay: 1.3,
				clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
				ease: "power4.out"
			})
			gsap.to(this.dom.title, {
				duration: 1.2,
				delay: 1.3,
				clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
				ease: "power4.out"
			})
		}
	}

	currentTime() {
		let date = new Date(); 
		let hh = date.getHours();
		let mm = date.getMinutes();
		let ss = date.getSeconds();
		
		hh = (hh < 10) ? "0" + hh : hh;
		mm = (mm < 10) ? "0" + mm : mm;
		ss = (ss < 10) ? "0" + ss : ss;

		let time = hh + ":" + mm + ":" + ss;

		this.dom.clock.innerText = time;
	}

	setWordAnimation(wrapper, index) {
		let _int = setInterval(() => {
			this._animWord(wrapper, index, loopIndex(this._current + 1, wrapper.querySelectorAll('.js-word-child').length));
		}, this._delay);
	}

	_animWord(wrapper, index, next) {
		let word = wrapper.querySelectorAll('.js-word-child');
		let tl = gsap.timeline({
			onComplete: () => {
				this._current = next;
			}
		});

		tl.fromTo(word[this._current], {
			y: '0%',
		}, {
			duration: 0.5,
			y: '-100%',
			ease: "power2.in",
			stagger: 0.08
		}, 0 + (index * 0.2))
		.to(word[next], { autoAlpha: 1, duration: 0 })
		.fromTo(word[next], {
			y: '100%',
		}, {
			duration: 0.5,
			y: '0%',
			ease: "power2.out",
			stagger: 0.08
		})
	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
