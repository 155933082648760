import Component from '~/components/component';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class HeroProject extends Component {
	isInstance = false;

	_classes = {
		bg: 'js-hero-project-bg',
		bottom: 'js-hero-project-bottom',
		intro: 'js-master-intro',
	};

	constructor(...args) {
		super(...args);
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.views = {};

		this.dom.views.bg = this.dom.component.querySelector( '.' + this._classes.bg );
		this.dom.views.bottom = this.dom.component.querySelector( '.' + this._classes.bottom );
		this.dom.views.intro = document.body.querySelector( '.' + this._classes.intro );
	}
	
	init() {
		super.init();

		this._heightComponent = this.dom.component.getBoundingClientRect().height;

		this._initFixedElement();
	}

	_initFixedElement() {

		ScrollTrigger.create({
			trigger: this.dom.views.bg,
			start: "top 0%",
			endTrigger: this.dom.views.intro,
			end: 'top 0%',
			scrub: true,
			pin: true
		});

		ScrollTrigger.create({
			trigger: this.dom.component,
			start: "top 0%",
			end: 'bottom 100%',
			pinSpacing: false,
			pin: this.dom.views.bottom,
		});
	}

	resize(width, height) {

		this._heightComponent = this.dom.component.getBoundingClientRect().height;
	}
	
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
