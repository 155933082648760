export const map = {
    components: {
        homepageProjects: require('components/homepage-projects/homepage-projects'),
        homepageValues: require('components/homepage-values/homepage-values'),
        dropdown: require('components/dropdown/dropdown'),
        sliderFull: require('components/slider-full/slider-full'),
        sliderArticles: require('components/slider-articles/slider-articles'),
        hero: require('components/hero/hero'),
        sliderGeneric: require('components/slider-generic/slider-generic'),
        form: require('components/form/form'),
        heroProject: require('components/hero/hero-project'),
        blockText: require('components/block-text/block-text'),
        backgroundGl: require('components/backgroundgl/backgroundgl'),
        button: require('components/button/button'),
        popinCollab: require('components/popin-collab/popin-collab'),
        newsCard: require('components/card/news-card'),
        projectCard: require('components/card/project-card'),
        pushActus: require('components/push-actus/push-actus'),
        testimonials: require('components/testimonials/testimonials'),
    },
    pages: {
        homepage: {
            index: require('pages/homepage/homepage-index')
        },
        brand: {
            index: require('pages/brand/brand-index'),
            entry: require('pages/brand/brand-entry')
        },
        expertise: {
            list: require('pages/expertise/expertise-list'),
            entry: require('pages/expertise/expertise-entry')
        },
        team: {
            index: require('pages/team/team-index')
        },
        contact: {
            index: require('pages/contact/contact-index')
        },
        group: {
            index: require('pages/group/group-index')
        },
        rse: {
            index: require('pages/rse/rse-index')
        },
        "case-study": {
            list: require('pages/case-study/case-study-list'),
            entry: require('pages/case-study/case-study-entry')
        },
        recruitement: {
            index: require('pages/recruitement/recruitement-index')
        },
        news: {
            list: require('pages/news/news-list'),
            entry: require('pages/news/news-entry')
        },
        legals: {
            index: require('pages/legals/legals-index')
        },
        generic: {
            index: require('pages/generic/generic-index')
        },
        "flexible-content": {
            index: require('pages/flexible-content/flexible-content-index')
        },
    }
};
