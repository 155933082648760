import Page from '~/pages/page';
import States from 'helpers/states'
import Store from 'helpers/Store';

export default class NewsList extends Page {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this._initTls();
        this.filterSelected = undefined;

    }
    _retrieveDOM(){
        this.dom.listCards = this.dom.page.querySelector('.js-container-cards');
        this.dom.loadMore = this.dom.page.querySelector('.js-load-more');
        this.dom.filters = this.dom.page.querySelectorAll('.js-filter');
        this.dom.filterAll = this.dom.page.querySelector('.js-filter-all');
    }
    init() {
        super.init();
        
        if (this.dom.loadMore) {
			this.dom.loadMore.addEventListener('click', ()=> {
				this.loadMoreAction();
			});
		}
        if (this.dom.filterAll) {
            this.dom.filterAll.addEventListener('click', ()=> {
                this.resetFilters();
            });
        }
        if (this.dom.filters) {
            for (let i = 0; i < this.dom.filters.length; i++) {
                this.dom.filters[i].addEventListener('click', ()=> {
                    this.manageFilters(this.dom.filters[i]);
                });
            }
        }
    }
    resetFilters() {
        this.filterSelected = [];
        this.dom.loadMore.style.display = '';
        this.dom.filterAll.classList.add('active');

        for (let i = 0; i < this.dom.filters.length; i++) {
            this.dom.filters[i].classList.remove('active');
        }

        this.ajax();
    }
    loadMoreAction() {
        let next_page = this.dom.loadMore.dataset.nextPage;

        if (parseInt(next_page) > 1) {
            this.ajax(next_page);
        }
    }
    manageFilters(filter){
        if(!filter.classList.contains('active') && filter.classList.contains('js-filter-all') ){
            this.resetFilters();
            filter.classList.add('active');
        }else if(!filter.classList.contains('active') && !filter.classList.contains('js-filter-all') ){
            this.filterSelected = filter.dataset.filterId;
            for (let i = 0; i < this.dom.filters.length; i++) {
                this.dom.filters[i].classList.remove('active')
            }
            filter.classList.add('active');
        }

        this.ajax();
    }
    animateCard(i , delay, old){
        setTimeout(() => {
            if (old) {
                this.dom.oldCards[ i ].classList.add('to-animate')
            }else{
                this.dom.newsCards[ i ].classList.remove('to-animate');
            }
        }, delay);
    }
    insertDom(response){

        if (response.data.page <= 1) {
            //Display old cards
            this.dom.oldCards = this.dom.listCards.querySelectorAll('.ct--card');

            for (let i = 0; i < this.dom.oldCards.length; i++) {
                let delay = (i + 1) * 200;
                this.animateCard( i, delay, true );
            }
            this.dom.listCards.innerHTML = response.data.posts;
        } else{
            this.dom.listCards.insertAdjacentHTML('beforeend', response.data.posts);
        }

        //Animation at insert
        this.dom.newsCards = this.dom.listCards.querySelectorAll('.to-animate');
        for (let i = 0; i < this.dom.newsCards.length; i++) {
            let delay = (i + 1) * 200;
            this.animateCard( i, delay , false);
        }
    }
    ajax(page)
    {
		let query = 'action=ek_ajax_news';
		let filterName = '&category=';
		if (this.filterSelected) query += filterName+this.filterSelected;
		if (page) query += '&page='+page;
		let xhr = new XMLHttpRequest();
		xhr.open('POST', AJAX_URL, true);
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		xhr.onreadystatechange = () => {
			let response = JSON.parse(xhr.responseText);
			if (xhr.readyState === 4 && xhr.status === 200) {
                if (response.success) {
                    this.insertDom(response);

                    if (response.data.load_more) {
                        this.dom.loadMore.dataset.nextPage = response.data.next_page;
                        this.dom.loadMore.style.display = '';
                    } else {
                        this.dom.loadMore.style.display = 'none';
                    }
                    this.updateCursorLink();
                }
				return;
			}
		};
		xhr.send(query);
    }
    // init() {
    //     super.init();
    // }


    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
