/**
* Imports
*/
import Component from '~/components/component';
// Three
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

// Post-processing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

// GUI
// import GUI from 'lil-gui'

// Shaders
import ekstendVertexShader from './shaders/ekstend/vertex.glsl'
import ekstendFragmentShader from './shaders/ekstend/fragment.glsl'
import grainVertexShader from './shaders/grain/vertex.glsl'
import grainFragmentShader from './shaders/grain/fragment.glsl'
import displacementVertexShader from './shaders/displacement/vertex.glsl'
import displacementFragmentShader from './shaders/displacement/fragment.glsl'


export default class BackgroundGl extends Component {
	constructor(...args) {
		super(...args);
		return;
		this._init();
		this._bind();
	}

	_init() {
		/**
		 * Base
		 */
		// Canvas
		this.canvas = this.dom.component;
		this.parentGl = this.dom.component.closest(".js-parent-gl");

		// Scene
		this.scene = new THREE.Scene()

		/**
		 * Sizes
		 */
		this.sizes = {
			width: this.parentGl.offsetWidth,
			height: this.parentGl.offsetHeight,
		}

		/**
		 * GUI
		 */
		// const gui = new GUI()
		// gui.close()

		/**
		 * Camera
		 */
		// Base camera
		this.camera = new THREE.PerspectiveCamera(
			75,
			this.sizes.width / this.sizes.height,
			0.1,
			100
		)
		this.scene.add(this.camera)

		// Controls
		// const controls = new OrbitControls(this.camera, canvas)
		// controls.enableDamping = true

		/**
		 * Ekstend sphere
		 */
		this.ekstendGeometry = new THREE.SphereGeometry(1, 32, 32)
		this.ekstendMaterial = new THREE.ShaderMaterial({
			side: THREE.DoubleSide,
			uniforms: {
				uTime: { value: 0 },
				uSpeed: { value: 0.05 },
				uSize: { value: 5.5 },
			},
			// wireframe: true,
			vertexShader: ekstendVertexShader,
			fragmentShader: ekstendFragmentShader,
		})
		this.ekstendSphere = new THREE.Mesh(this.ekstendGeometry, this.ekstendMaterial)
		this.scene.add(this.ekstendSphere)

		/**
		 * Grain sphere
		 */
		this.grainGeometry = new THREE.SphereGeometry(0.99, 32, 32)
		this.grainMaterial = new THREE.ShaderMaterial({
			side: THREE.DoubleSide,
			uniforms: {
				uOpacity: { value: 0.1 },
			},
			vertexShader: grainVertexShader,
			fragmentShader: grainFragmentShader,
			transparent: true,
		})
		this.grainSphere = new THREE.Mesh(this.grainGeometry, this.grainMaterial)
		this.scene.add(this.grainSphere)

		/**
		 * GUI: Ekstend shader
		 */
		// const ekstendGui = gui.addFolder('Ekstend shader')

		// ekstendGui
		// 	.add(ekstendMaterial.uniforms.uSpeed, 'value')
		// 	.min(0)
		// 	.max(1)
		// 	.step(0.01)
		// 	.name('uSpeed')

		// ekstendGui
		// 	.add(ekstendMaterial.uniforms.uSize, 'value')
		// 	.min(1)
		// 	.max(10)
		// 	.step(0.01)
		// 	.name('uSize')

		/**
		 * GUI: Grain shader
		 */
		// const grainGui = gui.addFolder('Grain shader')
		// grainGui
		// 	.add(grainMaterial.uniforms.uOpacity, 'value')
		// 	.min(0)
		// 	.max(1)
		// 	.step(0.01)
		// 	.name('uOpacity')

		/**
		 * Mouse
		 */
		this.mouse = new THREE.Vector2()
		this.followMouse = new THREE.Vector2()
		this.prevMouse = new THREE.Vector2()

		this.speed = 0
		this.targetSpeed = 0

		/**
		 * Renderer
		 */
		this.renderer = new THREE.WebGLRenderer({
			canvas: this.canvas,
			antialias: true,
		})
		this.renderer.setSize(this.sizes.width, this.sizes.height)
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		/**
		 * Post-processing
		 */
		this.effectComposer = new EffectComposer(this.renderer)
		this.effectComposer.setSize(this.sizes.width, this.sizes.height)
		this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		this.renderPass = new RenderPass(this.scene, this.camera)
		this.effectComposer.addPass(this.renderPass)

		// Hover displacement
		this.displacementPass = new ShaderPass({
		uniforms: {
			tDiffuse: { value: null },
			resolution: {
			value: new THREE.Vector2(1, this.sizes.height / this.sizes.width),
			},
			uMouse: { value: this.mouse },
			uVelo: { value: 0.0 },
		},
		vertexShader: displacementVertexShader,
		fragmentShader: displacementFragmentShader,
		})
		this.displacementPass.renderToScreen = true
		this.effectComposer.addPass(this.displacementPass)

		// Animated film grain
		// const filmPass = new FilmPass(0.25, 0, 0, false)
		// this.effectComposer.addPass(filmPass)

		/**
		 * Animate
		 */
		this.clock = new THREE.Clock()
		this.lastElapsedTime = 0

		this.tick();
	}

	_bind() {
		window.addEventListener('resize', () => {
			// Update sizes
			this.sizes.width = this.parentGl.offsetWidth
			this.sizes.height = this.parentGl.offsetHeight

			// Update camera
			this.camera.aspect = this.sizes.width / this.sizes.height
			this.camera.updateProjectionMatrix()

			// Update renderer
			this.renderer.setSize(this.sizes.width, this.sizes.height)
			this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		})

		// Mouse position
		window.addEventListener('mousemove', (event) => {
			this.mouse.x = event.clientX / this.sizes.width
			this.mouse.y = 1 - event.clientY / this.sizes.height
		})
	}

	// Mouse speed
	getSpeed() {
		this.speed = Math.sqrt((this.prevMouse.x - this.mouse.x) ** 2 + (this.prevMouse.y - this.mouse.y) ** 2)

		this.targetSpeed -= 0.1 * (this.targetSpeed - this.speed)
		this.followMouse.x -= 0.1 * (this.followMouse.x - this.mouse.x)
		this.followMouse.y -= 0.1 * (this.followMouse.y - this.mouse.y)

		this.prevMouse.x = this.mouse.x
		this.prevMouse.y = this.mouse.y
	}

	tick() {
		this.elapsedTime = this.clock.getElapsedTime()
		this.deltaTime = this.elapsedTime - this.lastElapsedTime
		this.lastElapsedTime =this.elapsedTime

		// Mouse
		this.getSpeed()

		// Ekstend shader
		this.ekstendMaterial.uniforms.uTime.value = this.elapsedTime

		// Displacement shader
		this.displacementPass.uniforms.uMouse.value = this.followMouse
		this.displacementPass.uniforms.uVelo.value = Math.min(this.targetSpeed, 0.05)
		this.targetSpeed *= 0.999

		// Update controls
		// controls.update()

		// Rotate camera
		this.camera.rotation.z += 0.001

		// Render
		// this.renderer.render(this.scene, this.camera)
		this.effectComposer.render()

		// Call tick again on the next frame
		window.requestAnimationFrame(this.tick.bind(this))
	}
}	
	
	
	