export const CookiesKeys = {
};

/**
 * Manage cookies. Create, delete & check
 */
class CookiesManager {
    constructor() {
        this.expires = 30;
        this.prefix = '%_';
    }

    set(key, value) {
        key = this.prefix + key;
        let date = new Date();
        date.setTime(date.getTime() + (this.expires * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + date.toUTCString();
        document.cookie = key + '=' + value + '; ' + expires + '; path=/';
    }

    get(key) {
        key = this.prefix + key;
        const name = key + '=';
        const cookies = document.cookie.split(';');
        for (let i = 0, j = cookies.length; i < j; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ')
                cookie = cookie.substring(1);
            if (cookie.indexOf(name) === 0)
                return cookie.substring(name.length, cookie.length);
        }
        return null;
    }

    check(key) {
        const value = this.get(key);
        if (value != null)
            return true;
        else
            return false;
    }
}

export default new CookiesManager();
