class Store {

  constructor () {
    this.Scrollbar = null;
    this.hpDBBIsBusy = false;
    this.useVideo = false;
    this.isMobile = document.body.classList.contains('mobile');
    this.isDesktop = !this.isMobile
    this.dbbIsBusy = false;
    this.dbbIndex = 0;
    this.dbbVideoIsPlaying = false;
    this.dbbCTAHovered = false;
    this.slide3Hit = false;
    this.loaderPause = false;
    window.storeDebug = this;
  }

  get scrollTop () {
    if (this.Scrollbar) {
      return this.Scrollbar.scrollTop
    } else {
        return window.scrollY;
    }
}
}

export default new Store()
