import Page from '~/pages/page';
import States from 'helpers/states'

export default class RecruitementIndex extends Page {
	constructor(...args) {
		super(...args);

		this._retrieveDOM();
		this._initTls();

		this._scrollTo();
	}

	init() {
		super.init();
	}

	_scrollTo() {
		let btnOffers = this.dom.page.querySelector('.js-offers');
		let targetOffers = this.dom.page.querySelector('.js-target-offers').getBoundingClientRect();
		
		let btnCandidate = this.dom.page.querySelector('.js-candidate');
		let targetCandidate = this.dom.page.querySelector('.js-target-candidate').getBoundingClientRect();

		btnOffers.addEventListener('click', () => {
			window.scrollTo({
				top: targetOffers.top,
				behavior: 'smooth'
			});
		})

		btnCandidate.addEventListener('click', () => {
			window.scrollTo({
				top: targetCandidate.top,
				behavior: 'smooth'
			});
		})
	}


	/**
	 * init() {}
	 * bind() {}
	 * unbind() {}
	 * resize(width, height) {}
	 * _destroy() {}
	 */
}
