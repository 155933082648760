import EventEmitter from 'eventemitter3';
import createjs from 'preload-js';
import { gsap } from "gsap";
import { CONFIG } from '../config-project';
import { Router, Emitter } from 'helpers'
import { getQuery } from 'utils/common'
import Store from 'helpers/Store'

export const LoaderEvent = {
	SET_PERCENT: '__le_set_percent',
	LOADING: '__le_loading',
	COMPLETED: '__le_loader_completed',
	MEDIA_LOADED: '__le_loader_loaded'
};

const DEBUG = false;

class Loader extends EventEmitter {
	constructor() {
		super();
		this.firstLoadCompleted = Promise.create();
		this.isLoading = false;
		this.assets = {};
		this.percent = 0;
		this.loadingSteps = {
			response: 0 //20
		};
		this._manifest = [];

		bindAll(this, [
			'_updatePercent',
			'_progressHandler',
			'_fileloadHandler',
			'_completeHandler',
			'_errorHandler'
		]);

		this._retrieveDom();
		this.init();
		this.bind();
		this.show();
		// test
		// window.loader = this;
	}

	_retrieveDom() {
		this._dom = {
			container: document.body.querySelector('.loader')
		};
	}

	init() {
		this.queue = new createjs.LoadQueue(true);
		this.queue.setMaxConnections(8);
	}

	bind() {
		this.queue.on('progress', this._progressHandler, this);
		this.queue.on('fileload', this._fileloadHandler, this);
		this.queue.on('complete', this._completeHandler, this);
		this.queue.on('error', this._errorHandler, this);
	}

	unbind() {
		this.queue.off('progress', this._progressHandler, this);
		this.queue.off('fileload', this._fileloadHandler, this);
		this.queue.off('complete', this._completeHandler, this);
		this.queue.off('error', this._errorHandler, this);
	}

	show() {
		this.visible = true;

		Store.loaderPause = true; // Pause gl update
		this._showTl = gsap.timeline();
	
		if (DEBUG) console.log('Loader::show?', Router.getUrl());
	}
	
	hide() {
		if (!this.visible) return;
		if (DEBUG) console.log('Loader::hide?', Router.getUrl())

		Store.loaderPause = false;
		this.visible = false;
		if(this._hideTl) this._hideTl.kill();
	
		this._hideTl = gsap.timeline({
			onComplete: () => {
				this.percent = 0;
				this.emit(LoaderEvent.MEDIA_LOADED);
				if (DEBUG) console.log('Loader::complete?', Router.getUrl())
				if (DEBUG) console.log('---');
			}
		});
		if (this._dom.container) {
			this._hideTl.to(this._dom.container, 0.5, { autoAlpha: 0, delay: .5 });
		}

		Emitter.emit('loader:hide');

		window.scrollTo(0, 0);
	}


	get(name) {
		if (!this.assets[name]) {
      return null
    }
    return this.assets[name]
	}

	preloadMedia(container, manifestId) {
		this._manifest = [];

		// Parse dom
		const media = [...container.querySelectorAll('[data-background]'), ...container.querySelectorAll('[data-src]')];
		for (let i = 0, j = media.length; i < j; i++) {
			const medium = media[i];
			const src = (medium.getAttribute('data-background')) ? medium.getAttribute('data-background') : medium.getAttribute('data-src');
			const id = medium.getAttribute('data-id') || 'img-' + i;
			const isBackground = (medium.getAttribute('data-background')) ? true : false;
			
			this._manifest.push({
				id,
				target: medium,
				src,
				isBackground,
				crossOrigin: true
			});
		}
		
		this.isLoading = true;
		if (this._manifest.length > 0)
			this.queue.loadManifest(this._manifest);
		else
			this._completeHandler();

		if (DEBUG) console.log('preload media', this._manifest)


	}

	getManifestId() {
		let query = getQuery('preload') || getQuery('scene');
		if (query) return query;

		let route = Router.getUrl();

		let routeManifest = [
			{ id: 'test', includes: 'test' },
			{ id: 'dbb', includes: 'dont-be-boring' },
		]

		let rId = 'home';
		routeManifest.forEach(( {id, includes}) => {
			if (includes) {
				if (route.indexOf(includes) >= 0) {
					rId = id;
				}
			}
		})


		return rId;
	}

	setPercent(percent = 0) {
		if (DEBUG) console.log('LOADER.js : setPercent', percent);
		if (this.percentTween) this.percentTween.kill();
		this.percentTween = gsap.to(this, .3, {
			percent,
			onUpdate: this._updatePercent
		});
		this.emit(LoaderEvent.SET_PERCENT, percent);
	}

	_updatePercent() {
		this.emit(LoaderEvent.LOADING, this.percent);
		if (this.percent === 100) {
			if (DEBUG) console.log('HIDE LOADER END UPDATEPERCENT');
			this.hide();
		}
	}

	AJAXresponseHandler() {
		if (DEBUG) console.log('LOADER.js : AJAXresponseHandler', this.loadingSteps.response);
		if (!CONFIG.IS_LAZY_LOADING)
			this._completeHandler();
		else
			this.setPercent(this.loadingSteps.response);
	}

	_progressHandler(event) {
		this.setPercent(this.loadingSteps.response + event.progress * (100 - this.loadingSteps.response));
	}

	_fileloadHandler(event) {

		if (event.item.target && event.item.target.classList) { // check if dom el
			if (event.item.isBackground)
				event.item.target.style.backgroundImage = 'url(' + event.item.src + ')';
			else
				event.item.target.setAttribute('src', event.item.src);
			event.item.target.classList.add('media-loaded');
			this.assets[event.item.id] = event.item.target;
		} else {
			this.assets[event.item.id] = event.result;
		}

	}

	_completeHandler(event) {
		if (!this.isLoading) return;
		this.isLoading = false;
		if (DEBUG) console.log('LOADER.js : _completeHandler');
		if (Store.Scrollbar) Store.Scrollbar.setPosition(0, 0)

		clearTimeout(this.completeTO);
		this.completeTO = setTimeout(()=>{
			this.emit(LoaderEvent.COMPLETED, this.assets)
			this.setPercent(100);
			this.firstLoadCompleted.resolve();
			Store.firstLoadCompleted = true;
		}, 30)
	}

	_errorHandler(event) {
		console.error(event);
	}

	get resources() {
		return this.assets
	}
}

export default new Loader();
