import Page from '~/pages/page';
import States from 'helpers/states'

export default class CaseStudyList extends Page {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this._initTls();

        this.filtersSelected = [];
        this._click = true;

    }

    _retrieveDOM(){
        this.dom.listCards = this.dom.page.querySelector('.js-container-cards');
        this.dom.loadMore = this.dom.page.querySelector('.js-load-more');
        this.dom.filters = this.dom.page.querySelectorAll('.js-filter-case');
        this.dom.filterAll = this.dom.page.querySelector('.js-filter-all');
        this.dom.containerFilters = this.dom.page.querySelector('.js-container-list-filters');
        this.dom.parentFilters = this.dom.page.querySelectorAll('.js-parent-filter');
        this.dom.dropdown = this.dom.page.querySelector('.js-dropdown');
        this.dom.containerChildrenFilters = this.dom.page.querySelector('.js-container-children-filter');
        this.dom.loadMoreLogo = this.dom.page.querySelector('.js-load-more-logo');
        this.dom.hiddenLogo = this.dom.page.querySelectorAll('.js-logo.hidden');
    }
    init() {
        super.init();
        
        if (this.dom.loadMore) {
			this.dom.loadMore.addEventListener('click', ()=> {
				if (this._click) {
                    this.loadMoreAction();
                }
			});
		}
        if (this.dom.filterAll) {
            this.dom.filterAll.addEventListener('click', ()=> {
                if (this._click) {
                    this.resetFilters();
                }
            });
        }
        if (this.dom.filters) {
            for (let i = 0; i < this.dom.filters.length; i++) {
                this.dom.filters[i].addEventListener('click', ()=> {
                    if (this._click) {
                        this.manageFilters(this.dom.filters[i]);
                    }
                });
            }
        }

        //for mobile
        if (this.dom.dropdown) {
            this.dom.dropdown.addEventListener('click', ()=> {
                this.dom.containerChildrenFilters.classList.toggle('open');
            });
        }
        if (this.dom.loadMoreLogo) {
            this.dom.loadMoreLogo.addEventListener('click', ()=> {
                for (let i = 0; i < this.dom.hiddenLogo.length; i++) {
                    this.dom.hiddenLogo[i].classList.remove('hidden');
                }
                this.dom.loadMoreLogo.style.display = "none";
            });
        }
    }
    resetFilters() {
        this.filtersSelected = [];
        this.dom.loadMore.style.display = '';
        this.dom.containerFilters.dataset.open = '';
        this.dom.filterAll.classList.add('active');

        for (let i = 0; i < this.dom.filters.length; i++) {
            this.dom.filters[i].classList.remove('active');
        }

        if (this.dom.dropdown) {
            this.dom.containerChildrenFilters.classList.remove('open');
        }

        this.ajax();
    }
    manageFilters(filter){
        this.dom.filterAll.classList.remove('active');

        if(filter.classList.contains('active')){
            const index = this.filtersSelected.indexOf(filter.dataset.filterId);
            this.filtersSelected.splice(index, 1);
            filter.classList.remove('active');

            if (filter.classList.contains('js-parent-filter')) {
                this.dom.containerFilters.dataset.open = '';
            }
        }else{
            if(filter.dataset.parentId) {
                this.dom.containerFilters.dataset.open = filter.dataset.parentId;

                for (let i = 0; i < this.dom.parentFilters.length; i++) {
                    const index = this.filtersSelected.indexOf(this.dom.parentFilters[i].dataset.filterId);
                    this.filtersSelected.splice(index, 1);
                    this.dom.parentFilters[i].classList.remove('active');
                }
            }

            this.filtersSelected.push(filter.dataset.filterId);
            filter.classList.add('active');
        }

        this.ajax();
    }
    loadMoreAction() {
        let nextPage = this.dom.loadMore.dataset.nextPage;

        if (parseInt(nextPage) > 1) {
            this.ajax(nextPage);
        }
    }
    animateCard(i , delay, old){
        setTimeout(() => {
            if (old) {
                this.dom.oldCards[ i ].classList.add('to-animate')
            }else{
                this.dom.newsCards[ i ].classList.remove('to-animate');
            }
        }, delay);
    }
    insertDom(response){

        if (response.data.page <= 1) {
            //Display old cards
            this.dom.oldCards = this.dom.listCards.querySelectorAll('.ct--project-card');

            for (let i = 0; i < this.dom.oldCards.length; i++) {
                let delay = (i + 1) * 200;
                this.animateCard( i, delay, true );
            }
            this.dom.listCards.innerHTML = response.data.posts;
        } else{
            this.dom.listCards.insertAdjacentHTML('beforeend', response.data.posts);
        }

        //Animation at insert
        this.dom.newsCards = this.dom.listCards.querySelectorAll('.to-animate');
        for (let i = 0; i < this.dom.newsCards.length; i++) {
            let delay = (i + 1) * 200;
            this.animateCard( i, delay , false);
        }
    }
    ajax(page)
    {
		let query = 'action=ek_ajax_case_study';
		let filterName = '&categories=';
        if (this.filtersSelected.length) query += filterName+this.filtersSelected.join(',');
		if (page) query += '&page='+page;
		let xhr = new XMLHttpRequest();
		xhr.open('POST', AJAX_URL, true);
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		xhr.onreadystatechange = () => {
			let response = JSON.parse(xhr.responseText);
			if (xhr.readyState === 4 && xhr.status === 200) {
                if (response.success) {
                    this.insertDom(response);

                    if (response.data.load_more) {
                        this.dom.loadMore.dataset.nextPage = response.data.next_page;
                        this.dom.loadMore.style.display = '';
                    } else {
                        this.dom.loadMore.style.display = 'none';
                    }
                    this.updateCursorLink();
                }
				return;
			}

            this._click = true;
		};
		xhr.send(query);
    }

    // init() {
    //     super.init();
    // }


    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
