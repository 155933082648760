import Raf from 'quark-raf'
import Stage from 'helpers/Stage'
import Store from 'helpers/Store'

class ViewportTracker {
  constructor (el, opts = {}) {
    if (!el) return;
    this.el = el;
    this.opts = opts;
    this.onViewportIn = opts.onViewportIn;
    this._onlyOnce = true;
    if (opts.onlyOnce === false) {
      this._onlyOnce = false;
    }

    this._offset = opts.offset || 50;
    this._isAnimated = false;

    this.resize()

    this._rzTo = setTimeout(()=>{
      this.resize();
    }, 400)

    this.bind();
  }

  bind() {
    this.rafData = Raf.add(this.update, 30);
  }
  
  destroy() {
    clearTimeout(this._rzTo);
    if(this._tl) this._tl.kill();
    Raf.remove(this.update);
  }

  resize() {
    if (this._isAnimated && this._onlyOnce || !this.el) return;

    this.bbox = this.el.getBoundingClientRect();
    this.offsetY = Store.scrollTop;

    this.top = this.bbox.y + Store.scrollTop - Stage.height
    this.bottom = this.bbox.y + Store.scrollTop - Stage.height + this.bbox.height
  }

  @autobind
  update(delta, time) {
    if (Store.scrollTop > this.top + this._offset) {
      if(this.onViewportIn) this.onViewportIn();
      if (this._onlyOnce) {
        Raf.remove(this.update);
      }
    }
  }
}

export default ViewportTracker
