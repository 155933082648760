import gsap from "gsap";
import debounce from 'lodash.debounce';

export default class CursorLink {
	constructor() {
		this._params = {
			duration: 0.4,
			toggle: 0.2,
			isVisible: false,
			isDisabled: false,
			isFirstInit: true
		}
		
		this.dom = {};
		this.dom.page = document.body;
		this.dom.cursor = this.dom.page.querySelector('.js-global-cursor');
		this.dom.label = this.dom.cursor.querySelector('.js-global-cursor-label')
		
		this.init();
	}
	
	init() {
		gsap.set(this.dom.cursor, {xPercent: -50, yPercent: -50, autoAlpha: 0, scale: 0.5});
		
		this.xTo = gsap.quickTo(this.dom.cursor, "x", {duration: this._params.duration, ease: "power3"});
		this.yTo = gsap.quickTo(this.dom.cursor, "y", {duration: this._params.duration, ease: "power3"});
		
		this.initDom();
		this._resize();
		window.addEventListener('resize', debounce(this._resize.bind(this), 200));
	}
	
	initDom() {
		this.dom.contents = document.body.querySelectorAll('[data-url]');
		if (this.dom.contents) {
			for (let i = 0; i < this.dom.contents.length; i++) {
				const wrapper = this.dom.contents[i];
				if (!wrapper.classList.contains('instancied')) {
					this._bindMove(wrapper);
				}
			}
		}
	}
	
	_bindMove(wrapper) {
		wrapper.classList.add('instancied');
		wrapper.addEventListener('click', () => {
			window.location.href = wrapper.getAttribute('data-url');
		});
		
		wrapper.addEventListener("pointermove", e => {
			this.xTo(e.clientX);
			this.yTo(e.clientY);
			
			this.dom.cursor.setAttribute('data-style', wrapper.getAttribute('data-style'));
		});
		wrapper.addEventListener('mouseenter', (e) => {
			!wrapper.classList.contains('hide-cursor') ? this._showCursor(e, wrapper) : this._hideCursor();
		});
		wrapper.addEventListener('mouseleave', this._hideCursor.bind(this));
	}
	
	_showCursor(e, wrapper) {
		if (this._params.isDisabled == false && this._params.isVisible == false) {
			this._params.isVisible = true;
			this.dom.label.innerHTML = wrapper.getAttribute('data-cursor');
	
			if (this._params.isFirstInit) {
				gsap.set(this.dom.cursor, {"x": e.clientX});
				gsap.set(this.dom.cursor, {"y": e.clientY});
				this._params.isFirstInit = false;
			}
			gsap.to(this.dom.cursor, {autoAlpha: 1, scale: 1, duration: this._params.toggle, ease: "power3"})
		}
		
	}
	_hideCursor() {
		if (this._params.isVisible != false) {
			gsap.to(this.dom.cursor, {autoAlpha: 0, scale: 0.8, duration: this._params.toggle, ease: "power3"})
			this._params.isVisible = false;
		}
	}
	_getWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	}
	_resize() {
		this._params.isDisabled = (this._getWidth() <= 640) ? true : false;
	}
	
	updateLinks() {
		this.initDom();
	}
}

