import Page from '~/pages/page';
import States from 'helpers/states'

export default class PopinCollabIndex extends Page {
	constructor(...args) {
		super(...args);

		this._retrieveDOM();
		this._initTls();
		this._checkParams();
	}
	
	_retrieveDOM() {
		this.dom.popin = this.dom.component;
		this.dom.page = document.querySelector('.page');
		this.dom.refCollab = this.dom.page.querySelectorAll('.js-ref-collab');
		this.dom.scroll = this.dom.popin.querySelector('.js-popin-scroll');
		this.dom.close = this.dom.popin.querySelector('.js-popin-close');
	}

	bind() {
		super.bind();

		delegate(document.body, '.js-call-collab', 'click', this._callPopin.bind(this), false);
		this.dom.close.addEventListener('click', this._closePopin.bind(this));
	}

	_checkParams() {
		const queryString = window.location.search;
		this.urlParams = new URLSearchParams(queryString);

		if (this.urlParams.get('id') != null) {
			this._openPopin(this.urlParams.get('id'));
		}
	}

	_callPopin(event) {
		const _id = event.delegateTarget.getAttribute('data-id');
		this._openPopin(_id);
	}

	_openPopin(_id) {
		const _domRef = this._getRef(_id);
		const _dataRef = _domRef.querySelectorAll('[data-ref]');
		
		// Auto Inject data to Popin
		_dataRef.forEach(data => {
			const _refInject = data.getAttribute('data-ref');
			this.dom.popin.querySelector('.js-popin-' + _refInject).innerHTML = data.innerHTML;
		});
		
		// Add Manually next Collab data
		const _nextId = _domRef.querySelector('.js-next').getAttribute('data-id');
		const _nextRef = this._getRef(_nextId);
		this.dom.popin.querySelector('.js-popin-next-name').innerHTML = _nextRef.querySelector('[data-ref="name"]').innerHTML;
		this.dom.popin.querySelector('.js-popin-next-function').innerHTML = _nextRef.querySelector('[data-ref="function"]').innerHTML;
		this.dom.popin.querySelector('.js-popin-next-link').setAttribute('data-id', _nextId);

		// Open popin
		this.dom.popin.classList.add('active');
		this.dom.scroll.scrollTop = 0;
	}

	_closePopin() {
		this.dom.popin.classList.remove('active');
	}

	/**
	 * Get ref Dom for popin
	 * @param {string} id 
	 * @returns Dom ref relative to clicked collab
	 */
	_getRef(id) {
		for (let i = 0; i < this.dom.refCollab.length; i++) {
			const refDom = this.dom.refCollab[i];
			if (refDom.getAttribute('data-id') == id) return refDom;
		}
	}


	// init() {
	//     super.init();
	// }


	/**
	 * init() {}
	 * bind() {}
	 * unbind() {}
	 * resize(width, height) {}
	 * _destroy() {}
	 */
}
