import Component from '~/components/component';
import DropdownManager from '../../helpers/dropdown';

export default class Dropdown extends Component {
	constructor(...args) {
		super(...args);
	}

	init() {
		super.init();

		this.DropdownManager = new DropdownManager({domComponent: this.dom.component});
	}
}
