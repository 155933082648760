import Page from '~/pages/page';
import States from 'helpers/states'

export default class TeamIndex extends Page {
	constructor(...args) {
		super(...args);

	
	}


	// init() {
	//     super.init();
	// }


	/**
	 * init() {}
	 * bind() {}
	 * unbind() {}
	 * resize(width, height) {}
	 * _destroy() {}
	 */
}
