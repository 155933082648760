// Here is all the polyfill of the app, and other global stuff.
window.Promise.create = function () { // defered promise, create the ability resolve, reject a stored promise
  const promise = new Promise((resolve, reject) => {
    this.temp_resolve = resolve
    this.temp_reject = reject
  })
  promise.resolve = this.temp_resolve
  promise.reject = this.temp_reject
  delete this.temp_resolve
  delete this.temp_reject
  return promise
}
