import Component from '~/components/component';
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import debounce from 'lodash.debounce';

gsap.registerPlugin(SplitText);

export default class NewCard extends Component {
	constructor(...args) {
		super(...args);

		this._retrieveDOM();
	}

	_retrieveDOM(){
		this.dom.title = this.dom.component.querySelector('.js-title');
	}
	
	init() {
		super.init();

		this.Split = new SplitText(this.dom.title , { type: "lines", linesClass: "line"});
		window.addEventListener('resize', debounce(this._resize.bind(this), 200));
	}

	_resize() {
		this.Split.revert();
		setTimeout(() => {
			this.Split.split();
		}, 200);
	}
}
