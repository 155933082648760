import Component from '~/components/component';
import Swiper, { Pagination } from 'swiper';

export default class SliderGeneric extends Component {
	constructor(...args) {
		super(...args);
	
		this._isInit = false;
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.slider = this.dom.component.querySelector('.js-slider');
	}

	init() {
		super.init();

		this._initSlider();
	}

	resize() {
		super.resize();


		if (this._params['1200'] && this._params['1200'] == "off" && this._getWidth() > 1200 && this._isInit) {
			this.Slider.destroy();
			this._isInit = false;
		} else if (this._params['1200'] && this._params['1200'] == "off" && this._getWidth() <= 1200 && !this._isInit) {
			this._initSlider();
		}
	}

	_initSlider() {
		if (this._isInit)
			return;
		
		this._isInit = true;
		this._params = JSON.parse(this.dom.slider.getAttribute('data-sizes'));
		
		this.Slider = new Swiper(this.dom.slider, {
			modules: [Pagination],
			slidesPerView: 1,
			spaceBetween: 10,
			pagination: {
				el: '.swiper-pagination',
			},
			breakpoints: {
				640: {
					slidesPerView: this._params['640'],
					spaceBetween: 10
				},
				835: {
					slidesPerView: this._params['835'],
					spaceBetween: 5
				}
			}
		});
	}

	_getWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
