import Component from '~/components/component';
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import debounce from 'lodash.debounce';

gsap.registerPlugin(SplitText);

export default class PushActus extends Component {
	constructor(...args) {
		super(...args);

		this.Split = [];
	}

	init() {
		super.init();

		this.dom.component.querySelectorAll('.js-title').forEach(element => {
			this.Split.push(new SplitText(element, { type: "lines", linesClass: "line"}))
		});
		
		window.addEventListener('resize', debounce(this._resize.bind(this), 200));
	}

	_resize() {

		this.Split.forEach(split => {
			split.revert();
			setTimeout(() => {
				split.split();
			}, 200);
		});

	}
}
