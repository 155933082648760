import Component from '~/components/component';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Store from '../../helpers/Store';

export default class BlockText extends Component {

	isInstance = {
		desktop: false,
		mobile: false
	};
	ScrollTriggerInstance = [];
	
	constructor(...args) {
		super(...args);

		this._retrieveDOM();

		this._current = 0;
		
		// this.Y_MAX = 10;
		// this.SMOOTH_FACTOR = 0.1;
		// this.scrollAmount = 0;
		// this.mustTick = false; // permet de mettre en pause le raf si l'anim est finie
		// this.rafId = undefined;
		// this.index = 0;
		// this.arrayScrollAmount = [];

		// this.maxSlides = this.dom.slides.length;
	}

	_retrieveDOM(){
		this.dom.images = this.dom.component.querySelectorAll('.ct--image');
		this.dom.text = this.dom.component.querySelectorAll('.js-text');
		this.dom.title = this.dom.component.querySelectorAll('.js-title');
		this.dom.observers = this.dom.component.querySelectorAll('.js-observer');
		this.dom.wrapper = this.dom.component.querySelector('.js-wrapper');	
		this.dom.step = this.dom.component.querySelector('.js-step-desktop .js-step');
		
		
		this.dom.slides = this.dom.component.querySelectorAll('.js-block');
		this.dom.images = this.dom.component.querySelectorAll('.js-img');
		this.dom.left = this.dom.component.querySelector('.js-block-left');
    }
	
	_initScrollFixed() {
		if (this.isInstance.desktop)
			return;

		// Reset params
		this.ScrollTriggerInstance = [];
		this.isInstance.desktop = true;
		
		this.ScrollTriggerInstance.push(ScrollTrigger.create({
			trigger: '.js-wrapper',
			start: "top 0%",
			end: "bottom 100%",
			pin: '.js-block-left',
			pinSpacing: false,
			// markers: true
		}));

		// Parse all markers
		for( let i = 0, j = this.dom.slides.length; i < j; i++ ) {
			let marker = this.dom.slides[ i ];
			this.ScrollTriggerInstance.push(ScrollTrigger.create({
				trigger: marker,
				start: "top 49%",
				end: "bottom 50%",
				// markers: true,
				onToggle: (self) => {
					if (self.isActive) {
						this._toggleImg(i);
					}
				}
			}));
		}
	}

	init() {
		super.init();

		// if (Store.isDesktop) {
		// 	this.handleResize();

		// 	window.addEventListener('scroll', this.handleScroll, { passive: true });
		// 	window.addEventListener('resize', this.handleResize);
		// }

	}

	_toggleImg(next) {
		if (next == this._current)
			return;

		gsap.to(this.dom.images[this._current], {
			clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
			duration: 0
		})
		gsap.to(this.dom.images[this._current], {
			clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
			duration: 0.5,
		})
		gsap.to(this.dom.images[next], {
			clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
			duration: 0
		})
		gsap.to(this.dom.images[next], {
			clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
			duration: 0.5,
		})

		this._current = next;


		// gsap.to(this.dom.images[index], {autoAlpha: 1, duration: 0.5});
	}


	resize() {
		super.resize();

		// Check slider desktop
		if (this._getWidth() > 832 && !this.isInstance.desktop) {
			this._initScrollFixed();
		} else if (this._getWidth() < 832 && this.isInstance.desktop) {
			this.ScrollTriggerInstance.forEach(instance => {
				instance.kill();
			});
			this.isInstance.desktop = false;
			this.ScrollTriggerInstance = [];
		}
	}

	_getWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	}


	// handleClickExplorer() {
	// 	window.scrollTo({
	// 		top: window.innerHeight,
	// 		behavior: 'smooth',
	// 	})
	// }
	// handleResize = () =>{
	// 	const { pageYOffset, innerHeight } = window;
	// 	const { top } = this.dom.component.getBoundingClientRect();
	// 	const offset = top + pageYOffset - innerHeight / 2;

	// 	for (let i = 0; i < this.dom.slides.length; i++) {
	// 		const element = {};
	// 		element.top = offset + innerHeight * i;
	// 		element.bottom = offset + innerHeight * (i + 1);
	// 		element.prev = 1;
	// 		element.next = 1;
	// 		this.arrayScrollAmount.push(element);
	// 		console.log(element);
	// 	}		
	// 	this.createObserver();
	// }
	// handleScroll= ()=>{
	// 	this.scrollAmount = window.pageYOffset;

	// 	if (this.rafId === undefined) {
	// 		this.mustTick = true;
	// 		this.rafId = window.requestAnimationFrame(this.raf);
	// 	}
	// }
	// raf = () => {
	// 	if (!this.mustTick) return;

	// 	//calcul du progress : 1 > 0 > -1
	// 	const start = this.arrayScrollAmount[this.index].top;
	// 	const end = this.arrayScrollAmount[this.index].bottom;
	// 	this.arrayScrollAmount[this.index].next = this.mapLinear(this.scrollAmount, start, end, 1, -1);

	// 	//Change index
	// 	this.dom.wrapper.dataset.index = this.index + 1;
	// 	this.dom.step.innerHTML = this.index + 1;

	// 	// lerp
	// 	this.arrayScrollAmount[this.index].prev = this.lerp(this.arrayScrollAmount[this.index].prev, this.arrayScrollAmount[this.index].next, this.SMOOTH_FACTOR);

	// 	if (Math.abs(this.arrayScrollAmount[this.index].prev - this.arrayScrollAmount[this.index].next) < 0.01) this.stopLoop();
	// 	else this.loop();
	// }
	// loop() {
	// 	this.rafId = window.requestAnimationFrame(this.raf);
	// }
	// stopLoop() {
	// 	this.mustTick = false;
	// 	cancelAnimationFrame(this.rafId);
	// 	this.rafId = undefined;
	// }
	// createObserver() {
	// 	const options = {
	// 		root: null,
	// 		rootMargin: "-50% 0%",
	// 	};
	// 	let sectionObserver = new IntersectionObserver(this.callbackObserver, options);

	// 	for (let i = 0; i < this.dom.observers.length; i++) {
	// 		sectionObserver.observe(this.dom.observers[i]);
	// 	}
	// }
	// callbackObserver = (entries, observer) => {
	// 	entries.forEach((entry) => {
	// 		if (entry.isIntersecting) {
	// 			this.index = parseInt(entry.target.dataset.index);
	// 		}
	// 	});
	// }
	// setHeight(){
	// 	const { innerHeight } = window;

	// 	this.dom.component.style.height = innerHeight + 'px';

	// }
	// mapLinear(nbr, in_min, in_max, out_min, out_max) {
	// 	return ((nbr - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
	// }
	// lerp(v0, v1, t) {
	// 	return v0 * (1 - t) + v1 * t;
	// }
}
