import { EventEmitter } from 'eventemitter3';
import CookiesManager from './cookies-manager';

// TODO: add checkbox management

/**
 * Validate forms inputs
 */
export default class FormValidator {
    constructor(options) {
        this._target = options.target;
        this._inputs = options.inputs;
        this._parent = options.parent || false;
        this._cookies = options.cookies || false;
        this._callback = null;
        this._formValidatorInputs = [];

        this._init();
    }

    _init() {
        for (let i = 0, j = this._inputs.length; i < j; i++) {
            const input = this._inputs[i];
            this._formValidatorInputs.push(new FormValidatorInput({ input, parent: this._parent, cookies: this._cookies }));
        }
        this.bind();
    }

    bind() {
        for (let i = 0, j = this._formValidatorInputs.length; i < j; i++) {
            const fvi = this._formValidatorInputs[i];
            fvi.on(FVIEvent.CHANGED, this._changedHandler, this);
            fvi.bind();
        }
    }

    unbind() {
        for (let i = 0, j = this._formValidatorInputs.length; i < j; i++) {
            const fvi = this._formValidatorInputs[i];
            fvi.off(FVIEvent.CHANGED, this._changedHandler, this);
            fvi.unbind();
        }
    }

    _changedHandler() {
        if (typeof this._callback !== 'function')
            return;

        let valid = 0;
        for (let i = 0, j = this._formValidatorInputs.length; i < j; i++) {
            if (this._formValidatorInputs[i].isValid())
                valid++;
        }

        this._callback.call(this._callback, valid);
    }

    on(callback) {
        this._callback = callback;
    }

    off() {
        this._callback = null;
    }

    /**
     * Check if the form is valid
     */
    isValid() {
        let success = true;
        for (let i = 0, j = this._formValidatorInputs.length; i < j; i++) {
            const fvi = this._formValidatorInputs[i];
            fvi.process();
            if (fvi.isValid() === false)
                success = false;
        }
        return success;
    }

    /**
     * Generate and return form data
     */
    getData() {
        let data = {};
        for (let i = 0, j = this._formValidatorInputs.length; i < j; i++) {
            const fvi = this._formValidatorInputs[i];
            data[fvi.getName()] = fvi.getValue();
        }
        return data;
    }
}

export const FVIEvent = {
    CHANGED: '__fvi_changed'
};

/**
 * Validate a form input with a specific type
 */
class FormValidatorInput extends EventEmitter {
    constructor(options) {
        super();

        this._input = options.input;
        this._parent = options.parent;
        this._cookies = options.cookies;

        this._type = this._input.getAttribute('data-type');
        this._name = this._input.getAttribute('name');

        this._value = null;
        this._valid = false;
        this._elementToToggle = (this._parent === false) ? this._input : this._input.parentNode;

        this._allowedTypes = ['text', 'email', 'phone', 'zipcode', 'number', 'date', 'not-required'];

        this.process = this.process.bind(this);
        this._focusHandler = this._focusHandler.bind(this);
        this._dateKeyupHandler = this._dateKeyupHandler.bind(this);

        this._init();
    }

    /**
     * Check all needed input pre-requisities
     */
    _init() {
        if (this._input.tagName !== 'INPUT')
            throw new Error('Element must be an INPUT');
        if (this._allowedTypes.indexOf(this._input.getAttribute('data-type')) < 0)
            throw new Error('Unknown input type \'' + this._input.getAttribute('data-type') + '\'. This class can\'t validate this type.');
        if (this._input.getAttribute('name') == null)
            throw new Error('Please specify attribute \'name\' in your input Element.');

        // Check cookies and set values
        if (CookiesManager.check(this._name)) {
            this._value = CookiesManager.get(this._name);
            this._input.value = this._value;
        }
    }

    bind() {
        this._input.addEventListener('blur', this.process);
        this._input.addEventListener('focus', this._focusHandler);
        if (this._type === 'date')
            this._input.addEventListener('keyup', this._dateKeyupHandler);
    }

    unbind() {
        this._input.removeEventListener('blur', this.process);
        this._input.removeEventListener('focus', this._focusHandler);
        if (this._type === 'date')
            this._input.removeEventListener('keyup', this._dateKeyupHandler);
    }

    /**
     * Process input validation
     */
    process() {
        this._valid = false;
        this._value = this._input.value;

        this._elementToToggle.classList.remove('focus');

        let regExp = '';

        switch (this._type) {
            case 'text':
                if (this._value.length >= 1)
                    this._valid = true;
                break;

            case 'email':
                regExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                break;

            case 'number':
                regExp = /^[0-9]{1}[0-9 ]*$/;
                break;

            case 'date':
                regExp = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/(19[0-9]{2}|200[0-9]|201[0-6])$/; // /^[0-9]{2}\/[]{1}[0-9]{1}\/[0-9]{4}$/;
                break;

            case 'zipcode':
                regExp = /^\d{5}(?:[-\s]\d{4})?$/;
                break;

            case 'phone':
                // Remove whitespaces and dots
                this._value = this._value.replace(/ /g, '').replace(/\./g, '');
                this._input.value = this._value;

                regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                break;

            case 'not-required':
                this._valid = true;
                break;

            default:
                break;
        }

        if (regExp !== '') {
            if (regExp.test(this._value))
                this._valid = true;
        }

        // Manage success/error DOM classes
        if (!this._valid) {
            this._elementToToggle.classList.remove('success');
            this._elementToToggle.classList.add('error');
        } else {
            this._elementToToggle.classList.remove('error');
            this._elementToToggle.classList.add('success');

            if (this._cookies)
                CookiesManager.set(this._name, this._value);
        }

        this.emit(FVIEvent.CHANGED, null);
    }

    _focusHandler() {
        this._elementToToggle.classList.add('focus');
    }

    _dateKeyupHandler(event) {
        const value = this._input.value;
        const length = value.length;

        // Check for slash keypress and already positionned slashes.
        if (event.which === 191 || event.which === 111) {
            if (value.charAt((value.length - 1)) === '/') {
                event.preventDefault();
                return;
            }
        }
        if (event.which !== 8) {
            if (length === 2 || length === 5)
                this._input.value = value + '/';
        }
    }

    getValue() {
        return this._value;
    }

    getName() {
        return this._name;
    }

    isValid() {
        return this._valid;
    }
}
