import Component from '~/components/component';
import Swiper from 'swiper';

export default class SliderArticles extends Component {
	constructor(...args) {
		super(...args);
		
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.videos = this.dom.component.querySelectorAll('.js-video-media');
		this.dom.cards = this.dom.component.querySelectorAll('.swiper-slide');
	}

	init() {
		super.init();

		for (let index = 0; index < this.dom.cards.length; index++) {
			this.dom.cards[index].addEventListener('click', ()=> {
				this.dom.cards[index].classList.add('playing', 'hide-cursor');
				let symbol = this.dom.videos[index].src.indexOf("?") > -1 ? "&" : "?";
				this.dom.videos[index].src += symbol + "autoplay=1&playsinline=1";
			});
		}
	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
