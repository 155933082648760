import EventEmitter from 'eventemitter3';

import States from '../helpers/states';
// import TextAnimation from 'helpers/TextAnimation';
import ViewportTracker from 'helpers/ViewportTracker';
import Raf from 'quark-raf';
import { gsap } from 'gsap';

export default class Component extends EventEmitter {
    constructor(component) {
        super();

        this.dom = {
            component,
            el: component
        };

        this.persist = this._getPersitance();
        this.mediaLoaded = false;

        this._timeouts = [];
        this._tls = [];
        this._textAnims = [];
        this._trackers = [];
        this._rafs = [];
    }

    init() {
    }

    bind() {
    }

    unbind() {
    }

    _unbind() {
        this._tls.forEach(tl=>{
            if (tl.kill) tl.kill();
        });
        this._timeouts.forEach(to=> clearTimeout(to));
        this._textAnims.forEach(anim=> anim.destroy());
        this._trackers.forEach(tracker=> tracker.destroy());
        this._rafs.forEach(raf=>{
            Raf.remove(raf.callback);
        });
    }

    _resize(width = States.width, height = States.height) {
        this._textAnims.forEach(anim=> anim.resize(width, height));
        this._trackers.forEach(tracker=> tracker.resize(width, height));
    }

    addViewportTracker(el, opts) {
        const tracker = new ViewportTracker(el, opts);
        this._trackers.push(tracker);
        if (tracker.rafData) this._rafs.push(tracker.rafData);
        return tracker;
    }

    // addTextAnimation(el, type, opts) {
    //     const anim = new TextAnimation(el, type, opts);
    //     this._textAnims.push(anim);
    //     if (anim.rafData) this._rafs.push(anim.rafData);
    //     return anim;
    // }

    addRaf(callback, fps) {
        const raf = Raf.add(callback, fps);
        this._rafs.push(raf);

        raf.clear = () => {
            Raf.remove(raf.callback);
        }
        return raf;
    }

    delayedCall(cb, time) {
        const to = setTimeout(cb, time);
        this._timeouts.push(to);
        return to;
    }

    getTl(opts) {
        let tl = gsap.timeline(opts);
        this._tls.push(tl);
        return tl;
    }

    mediaLoadedHandler() {
        this.mediaLoaded = true;
    }

    resize(width = States.width, height = States.height) {
    }

    _find(selector, container = this.dom.component) {
        return findDOM(selector, container);
    }

    _findAll(selector, container = this.dom.component) {
        return findAllDOM(selector, container);
    }

    _getPersitance() {
        return !(States.dom.body.contains(this.dom.component) && States.dom.page.contains(this.dom.component));
    }

    _scroll( currentScroll ) {
        
    }
}
