import { CONFIG } from '../config-project';
import States from '../helpers/states';
import Store from '../helpers/Store';
import SimpleSharePopin from '../helpers/simple-share-popin';
import CursorLink from '../helpers/cursor-link';
// import objectFitPolyfill from 'objectFitPolyfill';
// import RafManager from '../helpers/raf-manager';

import { gsap } from "gsap";
import ScrollSmoother from 'gsap/ScrollSmoother';
// import Scrollbar from 'smooth-scrollbar';
 
// import SmoothScrollMagic from '../helpers/smooth-scroll-magic';

// import Scroll from '../helpers/scroll';
// import WebGLCanvas from '../webgl/webgl-canvas';
// import testFS from '../webgl/shaders/test.frag';

import Component from '../components/component';

export const PageEvent = {
	NEXT: '__pe_next',
	PAGE_DESTROYED: '__pe_page_destroyed'
};

gsap.registerPlugin(ScrollSmoother);
export default class Page extends Component {

	_scrollValue = {
		current: null,
		prev: null
	};

	constructor(page, components) {
		super();

		this.dom = {
			component: page,
			page,
			main: this._find('main'),
			scrollable: this._find('.scrollable'),
			container: this._find('.container'),
			scrollSections: this._findAll('.js-scroll-section'),
			socialShare: this._findAll('[data-social]'),
			menuMobile: this._find('.js-menu-mobile'),
			callMenuMobile: this._find('.js-open-menu'),
			closeMenuMobile: this._find('.js-close-menu'),
			menuFixed: this._find('.js-menu-fixed'),
			hero: this._find('.js-hero'),
		};

		this.Components = components;

		if (Store.Scrollbar) Store.Scrollbar.locked = false;

		// if (CONFIG.IS_SMOOTH_SCROLL) {
		// 	this.dom.main.classList.add('is-smooth-scroll');
		// 	this.Scrollbar = Scrollbar.init(this.dom.main);

		// 	// this.SmoothScrollMagic = new SmoothScrollMagic(this.Scrollbar, this.dom.scrollSections);
		// }

		// this.scroll = new Scroll({
		//     direction: 'vertical',
		//     native: false,
		//     noscrollbar: false,
		//     listener: this.dom.page,
		//     section: this.dom.scrollable,
		//     sections: this.dom.scrollSections,
		//     ease: 0.1,
		//     vs: {
		//         preventTouch: true,
		//         passive: States.passive,
		//         sensibility: 1
		//     }
		// });

		// this.webGLCanvas = new WebGLCanvas({
		//     fragmentShader: testFS,
		//     uniforms: {
		//         uTexture: { type: 'sampler2D', value: this._find('img') },
		//         uTime: { type: '1f', value: 0.0 },
		//         uSpeed: { type: '1f', value: 1.0 }
		//     }
		// });
		// this.webGLCanvas.textures['uTexture'].clamp();

		bindAll(this, [
			'_update',
			'_scrollHandler',
			'_next',
			'_destroy'
		]);

		this._showTl = gsap.timeline({ paused: true });
		this._hideTl = gsap.timeline({
			paused: true,
			onStart: () => {
				this.dom.page.style.position = 'absolute';
				this.dom.page.style.top = -getWindowPosition() + 'px';

				// Reset scroll
				document.scrollingElement.scrollTop = document.scrollingElement.scrollLeft =
				document.body.scrollTop = document.body.scrollLeft =
				document.documentElement.scrollTop = document.documentElement.scrollLeft =
				window.scrollTop = window.scrollLeft = 0;
			},
			onComplete: this._destroy
		});
	}

	_retrieveDOM() {}

	_initTls() {
		this._showTl
			.from(this.dom.page, { autoAlpha: 0, duration: 0 }, 0)

		this._hideTl
			.to(this.dom.page, { autoAlpha: 0, duration: 0 }, 0)
			.add(() => {
				this._next();
			}, 0)
	}

	init() {
		new SimpleSharePopin(this.dom.socialShare);
		this.CursorLink = new CursorLink();

		this.ScrollSmoother = ScrollSmoother.create({
			smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
			effects: true,           // looks for data-speed and data-lag attributes on elements
			smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices),
			onUpdate: (e) => {
				if (e.scrollTrigger) {
					if (e.scrollTrigger.direction == -1 && e.scrollTop() > window.screen.height) {
						this.dom.menuFixed.classList.add('active');
					} else {
						this.dom.menuFixed.classList.remove('active');
					}
				}
			}
		  });
	}

	updateCursorLink() {
		this.CursorLink.updateLinks();
	}

	bind() {
		// RafManager.on(this._update);
		// if (this.scroll)
		//     this.scroll.on(this._scrollHandler);

		[ '_scroll' ]
			.forEach((fn) => this[fn] = this[fn].bind(this));
		window.addEventListener( 'scroll' , this._scroll, { passive: true });

		if (this.dom.callMenuMobile) {
			this.dom.callMenuMobile.addEventListener('click', this._openMenu.bind(this));
		}
		if (this.dom.closeMenuMobile) {
			this.dom.closeMenuMobile.addEventListener('click', this._closeMenu.bind(this));
		}
	}

	_openMenu(){
		this.dom.menuMobile.classList.add('active');
		document.body.classList.add('no-scroll');
	}

	_closeMenu(){
		this.dom.menuMobile.classList.remove('active');
		document.body.classList.remove('no-scroll');
	}

	unbind() {
		// RafManager.off(this._update);
		// if (this.scroll)
		//     this.scroll.off(this._scrollHandler);

		window.removeEventListener( 'scroll' , this._scroll );
	}

	mediaLoadedHandler() {
		this.mediaLoaded = true;
	}

	show() {
		this._showTl.play();
	}

	hide() {
		this._showTl.kill();
		this._hideTl.play();
	}

	_update(delta, time) {
		// if (this.webGLCanvas) {
		//     this.webGLCanvas.setUniform('uTime', time);
		//     this.webGLCanvas.render();
		// }
	}

	_scrollHandler(currentScroll) {
	}

	_scroll() {
		// this._scrollValue.current = window.scrollY;

		// this._scrollValue.current = window.pageYOffset || document.documentElement.scrollTop;
		// if (this._scrollValue.current < this._scrollValue.prev && this._scrollValue.current > (this.dom.hero.clientHeight - this.dom.hero.clientHeight / 3)) {
		// 	this.dom.menuFixed.classList.add('active');
		// 	console.log('match');
		// } else {
		// 	console.log('delete');
		// 	this.dom.menuFixed.classList.remove('active');
		// }
		// this._scrollValue.prev = this._scrollValue.current <= 0 ? 0 : this._scrollValue.current; 

		// Example
		// if( this.Components.hasOwnProperty( 'Menu' ) ) {
		//     this.Components[ 'Menu' ]._scroll( this._scrollValue.current );
		// }
	}

	resize(width = States.width, height = States.height) {
		// if (this.scroll)
		//     this.scroll.resize(width, height);

		// if (this.webGLCanvas)
		//     this.webGLCanvas.resize(width, height);
	}

	_next() {
		this.emit(PageEvent.NEXT);
	}

	_destroy() {
		// if (this.scroll)
		//     this.scroll.destroy();

		// if (this.webGLCanvas)
		//     this.webGLCanvas.destroy();

		this._next();
		this.emit(PageEvent.PAGE_DESTROYED);
	}

	_getPersitance() {
		return false;
	}
}
