import Component from '~/components/component';
import Swiper, { Navigation } from 'swiper';

export default class Testimonials extends Component {
	constructor(...args) {
		super(...args);
		
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.slider = this.dom.component.querySelector('.js-slider');
		this.dom.next = this.dom.component.querySelector('.js-next');
		this.dom.prev = this.dom.component.querySelector('.js-prev');
	}

	init() {
		super.init();

		this.Slider = new Swiper
		this.Slider = new Swiper(this.dom.slider, {
			modules: [Navigation],
			slidesPerView: 1,
			spaceBetween: 30,
			navigation: {
				nextEl: this.dom.next,
				prevEl: this.dom.prev,
			},
			breakpoints: {
				835: {
					slidesPerView: 3,
					spaceBetween: 30
				},
			}
		});
	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
