import Component from '~/components/component';
// import Swiper JS
// import Swiper from 'swiper';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

export default class HomepageValues extends Component {
	constructor(...args) {
		super(...args);
		this._current = 0;
		this._duration = 5000;
		this._canClick = true;
		this._intSlider = null;
		
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.image = this.dom.component.querySelectorAll('.js-image');
		
		this.dom.svg = this.dom.component.querySelectorAll('.js-svg');
		this.dom.subtitle = this.dom.component.querySelectorAll('.js-subtitle');
		this.dom.content = this.dom.component.querySelectorAll('.js-content');
		
		this.dom.line = this.dom.component.querySelectorAll('.js-line');
	}
	
	init() {
		super.init();

		this._initSlider();
	}

	bind() {
		super.bind();
	}

	_initSlider() {
		for (let i = 0; i < this.dom.line.length; i++) {
			const line = this.dom.line[i];
			new SplitText(line, { type: "lines", linesClass: "lineChild" });
			new SplitText(line, { type: "lines", linesClass: "lineParent" });
		}
	
		// Texts
		const initDom = [this.dom.svg[this._current].querySelectorAll('.lineChild'), this.dom.subtitle[this._current].querySelectorAll('.lineChild'), this.dom.content[this._current].querySelectorAll('.lineChild')]
		gsap.set(initDom, { y: '0%' });

		// Images
		gsap.set(this.dom.image[this._current], { zIndex: 3 });

		// Params
		this._slideLength = this.dom.image.length;

		this._intSlider = setInterval(() => {
			this._switchTo(loopIndex(this._current + 1, this._slideLength));
		}, this._duration);
	}

	_switchTo(next) {
		if (!this._canClick)
			return;
		
		this._canClick = false;
		gsap.set(this.dom.image[next], { zIndex: 2 });

		let tl = gsap.timeline({
			onComplete: () => {
				gsap.set(this.dom.image[this._current], { zIndex: 1, height: '100%' });
				gsap.set(this.dom.image[next], { zIndex: 3, height: '100%' });
				
				this._current = next;
				this._canClick = true;
			}
		});

		// Contents
		tl.fromTo(this.dom.content[this._current].querySelectorAll('.lineChild'), {
			y: '0%',
		}, {
			duration: 0.5,
			y: '-100%',
			ease: "power2.in",
			stagger: 0.08
		}, 0)
		.fromTo(this.dom.content[next].querySelectorAll('.lineChild'), {
			y: '100%',
		}, {
			duration: 0.5,
			y: '0%',
			ease: "power2.out",
			stagger: 0.08
		},);

		// Subtitle
		tl.fromTo(this.dom.subtitle[this._current].querySelectorAll('.lineChild'), {
			y: '0%',
		}, {
			duration: 0.5,
			y: '-100%',
			ease: "power2.in",
			stagger: 0.08
		}, 0)
		.fromTo(this.dom.subtitle[next].querySelectorAll('.lineChild'), {
			y: '100%',
		}, {
			duration: 0.5,
			y: '0%',
			ease: "power2.out",
			stagger: 0.08
		});

		// Svg
		tl.fromTo(this.dom.svg[this._current].querySelectorAll('.lineChild'), {
			y: '0%',
		}, {
			duration: 1,
			y: '-100%',
			ease: "power2.in",
			stagger: 0.08
		}, 0)
		.fromTo(this.dom.svg[next].querySelectorAll('.lineChild'), {
			y: '100%',
		}, {
			duration: 0.8,
			y: '0%',
			ease: "power2.out",
			stagger: 0.08
		}, 0.7);
		


		// IMAGES
		tl.fromTo(this.dom.image[this._current], {
				height: '100%'
			}, {
				duration: 1.4,
				height: '0%',
				ease: "power2.out"
			}, 0.3)
			.fromTo(this.dom.image[this._current].querySelector('.js-bg'), {
				y: 0
			}, {
				duration: 1.4,
				y: -100,
				ease: "power2.out"
			}, 0.3)
			.fromTo(this.dom.image[next].querySelector('.js-bg'), {
				y: 100
			}, {
				duration: 1.4,
				y: 0,
				ease: "power2.out"
			}, 0.3);


	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
