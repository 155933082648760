export function isArray (val) {
  return !!(val && val.constructor === Array)
}

export function isObject (val) {
  return (val === Object(val) && !isArray(val))
}

export function isWorker () {
  return (typeof importScripts === 'function')
}

export function shuffleArray (array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
}

export function supportsVideoType(type) {
  let video;

  // Allow user to create shortcuts, i.e. just "webm"
  let formats = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"'
  };

  if(!video) {
    video = document.createElement('video')
  }

  return video.canPlayType(formats[type] || type);
}

/**
 * Generate a UUID (Universal Unique Identifier).
 * Format : xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
 */
export function uuid () {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`
}

export function getQuery (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  var results = regex.exec(url)
  if (!results) return false
  if (!results[2]) return true

  var result = decodeURIComponent(results[2].replace(/\+/g, ' '))
  if (result === '' || result === 'true') result = true
  else if (result === null || result === 'false') result = false
  return result
}


export function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
  let doRatio = width / height
  let cRatio = containerWidth / containerHeight
  let targetWidth = 0
  let targetHeight = 0
  let test = contains ? (doRatio > cRatio) : (doRatio < cRatio)

  if (test) {
      targetWidth = containerWidth
      targetHeight = targetWidth / doRatio
  } else {
      targetHeight = containerHeight
      targetWidth = targetHeight * doRatio
  }

  return {
      width: targetWidth,
      height: targetHeight,
      x: (containerWidth - targetWidth) / 2,
      y: (containerHeight - targetHeight) / 2
  }
}