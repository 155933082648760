import Component from '~/components/component';
import FormValidator from '../../helpers/form-validator';

export default class Form extends Component {
	constructor(...args) {
		super(...args);
		
		this._retrieveDOM();

		this._sending = true;
		this.captcha = undefined;
	}
	
	_retrieveDOM() {
		this.dom.inputs = this.dom.component.querySelectorAll('.js-contact-input.required');
        this.dom.message = this.dom.component.querySelector('.js-message');
		this.dom.subject = this.dom.component.querySelector('.js-subject');
        this.dom.conditions = this.dom.component.querySelector('.js-conditions');
        this.dom.send = this.dom.component.querySelector('.js-send');
        this.dom.success = this.dom.component.querySelector('.js-success');
		this.dom.error = this.dom.component.querySelector('.js-error');
		this.dom.errorConditions = this.dom.component.querySelector('.js-error-conditions');
		this.dom.captcha = this.dom.component.querySelector('.js-captcha');
	}

	init() {
		super.init();

		this._Validator = new FormValidator({
            'inputs' : this.dom.inputs,
            'parent' : true
        });

        this.dom.send.addEventListener('click', () => {
			if (this._sending) {
				this.validate();				
			}
        });

		let onloadCallback = () => {
			this.captcha = grecaptcha.render(this.dom.captcha, {
				'sitekey' : '6Ld3RyYjAAAAAG8P4SkvsPq1wxfI-3zmhQxHODZI'
			});
		};
		window.onloadCallback = onloadCallback;

	}

	validate(){
        let data = new FormData();

		this._sending = false;

        if( this._Validator.isValid() ) {

            let inputs = this._Validator.getData();
            for(let item in inputs) {
                data.append(item, inputs[item]);
            }

        } else {
            this.manageMessage('error');
        }
	
		//Check subject
		if( this.dom.subject.value ) {

			data.append('subject', this.dom.subject.value);
			this.dom.subject.classList.remove('error');

        } else {
			this.dom.subject.classList.add('error');
            this.manageMessage('error');
			return false;
        }

        //Get message
        data.append('message', this.dom.message.value);

        // //Check conditions
        if (this.dom.conditions.checked && grecaptcha.getResponse(this.captcha).length !== 0) {
			this.request(data);
        }else{
            this.manageMessage('errorConditions');
        }
    }
	resetForm(){
		const inputs = this.dom.component.querySelectorAll('input');
		for (let index = 0; index < inputs.length; index++) {
			inputs[index].value = "";
		}
		this.dom.message.value = "";
	}
	manageMessage(type){
        if (type === "success") {
            this.dom.success.classList.add('show');
            setTimeout(()=> {
                this.dom.success.classList.remove('show');
				this.resetForm();
				this._sending = true;
            }, 5000);
        }else if (type === "errorConditions"){
            this.dom.errorConditions.classList.add('show');
			this._sending = true;
            setTimeout(()=> {
                this.dom.errorConditions.classList.remove('show');
            }, 10000);
        } else if (type === "error"){
            this.dom.error.classList.add('show');
			this._sending = true;
            setTimeout(()=> {
                this.dom.error.classList.remove('show');
            }, 10000);
        };
    }

	request(data){
        
        const xhr = new XMLHttpRequest();
        xhr.open( 'POST', AJAX_URL + '?action=ek_contact_send_form', true );
        xhr.onreadystatechange = () => {
			let response = JSON.parse(xhr.response);
			if (xhr.readyState === 4 && xhr.status === 200) {
				if (response.success) {
                    this.manageMessage('success');
				}else{
                    this.manageMessage('error');
                }
			}
		};
		xhr.send(data);
    }
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
