import Component from '~/components/component';
import {gsap} from "gsap";


export default class Button extends Component {
	constructor(...args) {
		super(...args);

		this._params = {
			duration: 0.4,
			toggle: 0.2
		}
		
		this._retrieveDOM();
	}
	
	_retrieveDOM() {
		this.dom.ref = this.dom.component.querySelector('.js-ref');
		this.dom.inner = this.dom.component.querySelector('.js-inner');
		this.dom.bg = this.dom.component.querySelector('.js-bg');
	}

	init() {
		super.init();
		
		this.xTo = gsap.quickTo(this.dom.inner, "x", {duration: this._params.duration, ease: "power3"});
		this.yTo = gsap.quickTo(this.dom.inner, "y", {duration: this._params.duration, ease: "power3"});
	}

	bind() {
		super.bind();

		this.dom.ref.addEventListener("mousemove", e => {
			var bounds = this.dom.ref.getBoundingClientRect();
			const x = e.clientX - bounds.left;
			const y = e.clientY - bounds.top;
			
			this.xTo((x - (this.dom.ref.offsetWidth / 2)) * 0.3);
			this.yTo((y - (this.dom.ref.offsetHeight / 2)) * 0.2);
		});
		
		this.dom.ref.addEventListener('mouseleave', () => {
			this.xTo(0);
			this.yTo(0);
		});
	}
	
	/*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
